import React, { useState } from "react";

const ContactUsForm = ({textColor}) => {

  const initialValue = {
    fname: "",
    lname: "",
    company: "",
    email: "",
  };

  const closePopup = () => {
    setSubmitted(false);
  };
  
  const [isSubmitted, setSubmitted] = useState(false);

  const [formState, setFormState] = useState(initialValue);

  const handleChange = (e) => {
    const curKey = e.target.getAttribute("name");
    const curValue =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;

    const val = (curKey === 'email' || curKey === 'company' ) ? curValue : `${curValue.slice(0, 1).toUpperCase()}${curValue.slice(1)}`

    setFormState({ ...formState, [curKey]: val});
  };

  function encode(data) {
    return Object.keys(data)
      .map(
        (key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
      )
      .join("&");
  }
  const handleSubmit = (event) => {
    event.preventDefault();
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": "contact",
        fname: formState.fname,
        lname: formState.lname,
        company: formState.company,
        email: formState.email,
      }),
    })
      .then(() => {
        setTimeout(() => {
          setSubmitted(true);
          setFormState(initialValue);
        }, 1000);
      })
      .catch((error) => alert(error));
  };
  
  return (
    <div className="relative">
      <form 
        className={`${isSubmitted ? `invisible` : ``} flex  flex-wrap -mx-4 text-right`}
        method="post"
        netlify-honeypot="bot-field"
        data-netlify="true"
        name="contact"
        onSubmit={handleSubmit}
        >
        <div className="my-3 px-4 flex flex-col-reverse w-full sm:w-1/2 text-left">
          <input
            className={`${ textColor !== "text-white" ? "border border-grey-4 focus:border-secondary pl-4" : "rounded-lg border-secondary border pl-4" } pr-4 rounded-lg py-3 w-full focus:outline-none`}
            onChange={handleChange}
            type="text"
            name="fname"
            id="fname"
            required
            value={formState.fname}
            placeholder="" />
          <label htmlFor="fname" className="block cursor-pointer">
            First Name
          </label>
        </div>
        <div className="my-3 px-4 flex flex-col-reverse w-full sm:w-1/2 text-left">
          <input
            className={`${ textColor !== "text-white" ? "border border-grey-4 focus:border-secondary pl-4" : "rounded-lg border-secondary border pl-4" } pr-4 rounded-lg py-3 w-full focus:outline-none`}
            onChange={handleChange}
            type="text"
            name="lname"
            required
            id="lname"
            value={formState.lname}
            placeholder="" />
          <label htmlFor="lname" className="block cursor-pointer">
            Last Name
          </label>
      
        </div>
        <div className="my-3 px-4 flex flex-col-reverse w-full text-left">
          <input
            className={`${ textColor !== "text-white" ? "border border-grey-4 focus:border-secondary pl-4" : "rounded-lg border-secondary border pl-4" } pr-4 rounded-lg py-3 w-full focus:outline-none`}
            onChange={handleChange}
            type="email"
            name="email"
            required
            id="email"
            value={formState.email}
            placeholder="" />
          <label htmlFor="email" className="block cursor-pointer">
            Work Email
          </label>
        </div>
        <div className="my-3 px-4 flex flex-col-reverse w-full text-left">
          <input
            className={`${ textColor !== "text-white" ? "border border-grey-4 focus:border-secondary pl-4" : "rounded-lg border-secondary border pl-4" } pr-4 rounded-lg py-3 w-full focus:outline-none`}
            onChange={handleChange}
            type="text"
            name="company"
            required
            id="company"
            value={formState.company}
            placeholder="" />
          <label htmlFor="company" className="block cursor-pointer">
            Company Name
          </label>
        </div>
        <div className="my-3 px-4 flex flex-col-reverse w-full ">
          <button 
            className="btn btn-accent" 
            type="submit"
            title="Submit Contact Us Form"
          >
              Schedule
          </button>
        </div>
      </form>
        {isSubmitted && (
          <div className={`h-64 text-center flex justify-center items-center flex-col absolute inset-0`}>
            <p className="font-light text-xl text-primary-oxfordblue">Your request has been sent.</p>
            <p className="text-grey-400 mb-6">We will reach you soon.</p>
            <p>
              <button 
                type="button" 
                className="text-accent font-bold"
                title="Back to Form"
                onClick={closePopup}
              >Back to form</button>
            </p>
          </div>
        )}
    </div>
  )
}

export default ContactUsForm;