

import React from "react";
import ContactUsForm from "./contact-form";
import { renderRTF } from "../rich-text";

const SectionContact = ({ data }) => {

  return (
    <section className={`bg-blue-light border-t-4 border-accent`} id="contact">
      <div className={` section rounded-none lg:rounded-3xl container relative overflow-hidden`}>
        <div className="container max-w-6xl">
          <div
            className={` flex-wrap items-center justify-center md:justify-between flex -mx-4`}
          >
            <div className={`w-full md:w-1/2 px-4`}>
              {renderRTF(data.text)}
            </div>
            <div className={`w-auto md:w-1/2 xl:w-5/12 text-center md:text-left md:w-auto px-4 pt-5`}>
              <ContactUsForm textColor={`text-secondary`} />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default SectionContact