import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const BlogCard = ({ post }) => {
  const image = getImage(post.image)
  return (
    <>
       <a 
        href={`/blog/${post.slug}`}
        className="w-full block rounded-2xl relative overflow-hidden aspect-w-4 aspect-h-3 hover:opacity-80">
        <div className="absolute inset-0">
          {post.image &&<GatsbyImage className={`w-full h-full`} alt={post.heading} image={image} />}
        </div>
      </a> 
      <div className="font-medium text-secondary-1 flex flex-wrap tracking-wide  mt-5 mb-3 -mx-2">
        {post.tags &&
          post.tags.map(tag => {
            return (
              <div className="px-2 w-auto"><a className="uppercase text-xs md:text-sm opacity-60 hover:opacity-100" href={`/blog/tag/${tag.slug}`}>{tag.title}</a></div>
            )
          })
        }

      </div>
      <a 
        href={`/blog/${post.slug}`}
        className="hover:opacity-80 block"
      >
        <p className="text-secondary-1 text-xl font-semibold font-medium mt-4 mb-3">{post.heading}</p>
        <p className="font-medium text-accent">
          Read post
          {" "}
          →
        </p>
      </a>         
    </>
  )
}

export default BlogCard