import React from "react"
import { graphql } from "gatsby"
import { Layout } from "../components/common";
import SEO from "../components/Seo"
import SectionContact from "../components/content/section-contact";
import BlogCard from "../components/blog/blog-card";


const IndexPage = ({ data }) => {
  const posts = data.posts.edges.map(e => e.node)
  const tags = data.tags.edges.map(e => e.node)
  const contact = data.contact.edges.map(e => e.node)[0]
  const categories = data.categories.edges.map(e => e.node)

  return (
    <Layout>
      <SEO
        title="Xaqt Blog"
        description="Get to know more about our company and services in XAQT's blog"
        canonical
      />

      <header className="pt-24 lg:pt-32 pb-4 lg:pb-6">
        <div className="container max-w-2xl text-center">
          <h1 className="font-semibold text-secondary-1">Blog</h1>
          <p className="text-secondary-1 text-xl">Get to know more about our company and services in XAQT's blog</p>
        </div>
      </header>

      <section className="pb-24 lg:pb-32">
        <div className="container">
          <div className="flex flex-wrap -mx-4">
            {posts.map( i => {
              return (
                <div
                  className="px-4 w-full md:w-1/2 lg:w-1/3 my-6">
                  <BlogCard post={i} />
                </div>
              )
            })}
          </div>
        </div>
      </section>

      <section className="pb-24 lg:pb-32 hidden">
        <div className="container">
          <h3>All Blog Tags</h3>

          <div className="flex flex-wrap -mx-4">
            {tags.map( i => {
              return (
                <a 
                  href={`/blog/tag/${i.slug}`}
                  className="px-4 w-full md:w-1/2 lg:w-1/4 my-6 hover:opacity-80">
                  <p className="text-accent font-medium mt-4 mb-3">{i.title}</p>
                </a>
              )
            })}
          </div>
        </div>
      </section>

      <section className="pb-24 lg:pb-32 hidden">
        <div className="container">
          <h3>All Blog Categories</h3>

          <div className="flex flex-wrap -mx-4">
            {categories.map( i => {
              return (
                <a 
                  href={`/blog/category/${i.slug}`}
                  className="px-4 w-full md:w-1/2 lg:w-1/4 my-6 hover:opacity-80">
                  <p className="text-accent font-medium mt-4 mb-3">{i.title}</p>
                </a>
              )
            })}
          </div>
        </div>
      </section>
      <SectionContact data={contact} />
    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query {

    contact: allContentfulSectionGetInTouch(filter: {id: {eq: "1ca522e9-383a-5d24-9ec1-1fbf7ad770cd"}}) {
      edges {
        node {
          text {
            raw
          }
        }
      }
    }

    tags: allContentfulTag {
      edges {
        node {
          title
          id
          slug
        }
      }
    }
    categories: allContentfulCategory {
      edges {
        node {
          title
          id
          slug
        }
      }
    }

    posts: allContentfulPost(
      sort: { order: DESC, fields: date }
    ) {
      
      edges {
        node {
          author {
            name
            lastName
            firstName
            bio {
              raw
            }
            slug
            avatar {
              gatsbyImageData(
                width: 192
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
              # fluid(maxWidth: 192,quality: 100) {
              # ...GatsbyContentfulFluid
              # }
            }
          }
          tags {
            title
            slug
          }
          categories {
            title
            slug
          }
          metaTitle
          metaDescription
          updatedAt(formatString: "MMMM DD yyyy")
          createdAt(formatString: "MMMM DD yyyy")
          heading
          slug
          image {
            # fluid(quality: 100) {
            #   ...GatsbyContentfulFluid
            # }
            gatsbyImageData(
              width: 394
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    }
  }
`
